import Headroom from "headroom.js";

let myElement = document.querySelector(".header");
let headroom  = new Headroom(myElement, {
    offset: {
        up: 50,
        down: 80
    }
});
headroom.init();

let isSearch = false;
jQuery( ".search-icon .elementor-social-icon" ).click(function() {
    if(!isSearch) {
        jQuery('.search-icon .elementor-social-icon i').removeClass('fas fa-search').addClass('fas fa-times');
        jQuery('#mega-menu-wrap-menu-1 #mega-menu-menu-1').css('display', 'none');
        jQuery('.search-field > .elementor-widget-container').css('display', 'block');
        // jQuery('.social-media-icons > .elementor-widget-container').css('display', 'none');
        jQuery('.elementor-widget-search-form input[type="search"].elementor-search-form__input').focus();
        isSearch = true;
    } else {
        jQuery('.search-icon .elementor-social-icon i').removeClass('fas fa-times').addClass('fas fa-search');
        jQuery('#mega-menu-wrap-menu-1 #mega-menu-menu-1').css('display', 'block');
        jQuery('.search-field > .elementor-widget-container').css('display', 'none');
        // jQuery('.social-media-icons > .elementor-widget-container').css('display', 'block');
        isSearch = false;
    }
});